@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: 'Poppins';
  src: url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap') format('woff2');
  font-display: swap;
}

body {
  font-family: 'Poppins', sans-serif;
}

.slick-prev:before,
.slick-next:before {
  color: #09529b !important;
}

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
  background: #cfd8dc;
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #90a4ae;
  border-radius: 10px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: "#757575";
}

.youtube-video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.swiper-pagination {
  margin-top: 20rem;
}

/* In your stylesheet or style block */
.box-shadow-custom:hover {
  box-shadow: 10px 10px 0 #2196f3;
}



/* Pagiantion Css start */

.pagination {
  @apply items-center flex
}


.pagination>li>a,
.pagination>li>span {
  @apply relative inline-flex items-center px-3 py-1.5 text-sm font-medium text-gray-700 cursor-pointer border border-gray-200 bg-white
}

/* .pagination>li:nth-child(2n + 1)>a,
.pagination>li:nth-child(2n + 1)>span {
  @apply bg-opacity-5
} */

.pagination>li.active>a {
  @apply bg-[#FFFFFF] text-[#2196F3]
}

/* Style the active class (and buttons on mouse-over) */
.pagination>li>a:hover {
  @apply bg-[#FFFFFF] text-[#2196F3]
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  @apply rounded-l block
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  @apply rounded-r block mr-0
}











/* .toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}

.toggle-checkbox:checked+.toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
} */